module.exports = {
  'main_navigation': {
    'courses': 'Meine Fortbildungen',
    'learningProfile': 'Fortbildungsprofil',
    'coursesDetail': 'Fortbildungs Details',
    'start': 'Start',
    'faq': 'Hausarzt TV',
  },
  'offset_navigation': {
    'close': 'Menü'
  },
  'pages': {
    'bossBooking': {
      'training': 'Fortbildung'
    },
    'start': {
      'slider': [
        {
          'headline': 'Herzlich willkommen'
        },
        {
          'headline': 'Die Delegation macht‘s – VERAH',
          'textbox': [
            'Sichern Sie sich auf unserer e-Akademie das konzentrierte Wissen exzellenter Referentinnen und Referenten. Ihr Terminkalender ist schon voll: Dann können wir Ihnen eine hochqualifizierte Weiterbildung Ihrer MFA zur VERAH empfehlen. Mit diesem',
            'Klick',
            'finden Sie alles rund um Delegation und Teamentwicklung und die Erfolgsgeschichte von über 15.000 VERAH',

          ],
          'link': 'https://www.verah.de/was-ist-verah/information-fuer-praxisinhaber'
        },
        {
          'headline': 'Immer up-to-date – Hausärztlicher Newsletter',
          'textbox': [
            'Unsere digitalen Fortbildungen bieten sorgfältig strukturierte Inhalte auf dem neuesten Stand der Hausarztmedizin und der Didaktik – ein moderner, multimedialer Standard für Ihre tägliche Praxis. Genau wie unser monatlicher Fortbildungsnewsletter mit den Besonderheiten aus Medizin, Politik und Wissenschaft. Nur ein',
            'Klick',
            'Und das Beste: Natürlich lesen Sie wann und wo Sie wollen.'
          ],
          'button': 'Mehr erfahren',
          'link': 'https://www.ihf-fobi.de/'
        }
      ],
      'gradient_teaser': [
        {
          'headline': 'Ihre offenen Online-Fortbildungen',
          'linkText': 'Alle anzeigen'
        },
        {
          'headline': 'Hausarzt TV',
          'linkText': 'Jetzt einschalten'
        }
      ],
      'categories': {
        'headline': 'Die Lernkategorien'
      },
      'teaser_slider': {
        'header': {
          'headline': 'Unsere Präsenzfortbildungen',
          'text': 'Sie haben Lust, sich mit Ihren Kolleginnen und Kollegen in Präsenz auszutauschen. Dann finden Sie zwischen den Fortbildungswochen oder den regionalen Hausärztetagen bis hin zur practica in Bad Orb sicherlich Ihr Fortbildungshighlight. Ein Klick auf „Details“ führt Sie auf die jeweiligen Veranstaltungsseite, mit einem weiteren Klick können Sie sich direkt anmelden.'
        },
        'teaser': [
          {
            'headline': 'Regionale Hausärztetage',
            'text': 'Fortbildung und Berufspolitik - Die ideale Ergänzung, auch in Ihrer Region.',
            'location': 'diverse Orte',
            'image': 'ihf_academy_start_teaser_slide_1.jpg',
            'link': 'https://aerzte.ihf-fortbildung.de/formate/kongresse-hausaerzte-und-fortbildungstage/',
            'button': 'Details',
            'type': "location"
          },
          {
            'headline': 'practica Bad Orb',
            'text': 'Moderne Fort- und Weiterbildung für das gesamte Praxisteam (47. practica).',
            'location': ' Jährlich Ende Oktober',
            'image': 'ihf_academy_start_teaser_slide_2.jpg',
            'link': 'https://aerzte.ihf-fortbildung.de/formate/practica/',
            'button': 'Details',
            'type': "date"
          },
          {
            'headline': 'Städte-Fortbildungswochenenden',
            'text': 'Fortbilden und gleichzeitig dem Alltagsstress entziehen – Kultur, Shopping oder ein Tag am Meer.',
            'location': 'Mehrmals Jährlich',
            'image': 'ihf_academy_start_teaser_slide_3.jpg',
            'link': 'https://aerzte.ihf-fortbildung.de/formate/staedte-fortbildungswochenenden/',
            'button': 'Details',
            'type': "date"
          },
          {
            'headline': 'Fortbildungswoche Niederlande',
            'text': 'Fortbildung mit der ganzen Familie - zwischen Nord- und Grevelingensee.',
            'location': 'Jährlich über Ostern',
            'image': 'ihf_academy_start_teaser_slide_4.jpg',
            'link': 'https://aerzte.ihf-fortbildung.de/formate/fortbildungswochen/',
            'button': 'Details',
            'type': "date"
          },
          {
            'headline': 'Fortbildungswoche Ostsee',
            'text': '„Fortbildung und Meer“ - an der Ostsee.',
            'location': 'Jährlich Anfang September',
            'image': 'ihf_academy_start_teaser_slide_5.jpg',
            'link': 'https://aerzte.ihf-fortbildung.de/formate/fortbildungswochen/ ',
            'button': 'Details',
            'type': "date"
          },
          {
            'headline': 'Fortbildungswoche Kanaren',
            'text': '„Entspannt fortbilden“ - außerhalb des Praxisalltages auf den Kanaren.',
            'location': 'Jährlich Ende Februar',
            'image': 'ihf_academy_start_teaser_slide_6.jpg',
            'link': 'https://aerzte.ihf-fortbildung.de/formate/fortbildungswochen/',
            'button': 'Details',
            'type': "date"
          },
          {
            'headline': 'Fortbildungswoche Gardasee',
            'text': 'Das IHF lädt Sie zur 1. IHF Fortbildungswoche am Gardasee sein. Sie sind von Anfang an mit dabei!',
            'location': 'Jährlich Mitte Mai',
            'image': 'ihf_academy_start_teaser_slide_6_2.jpg',
            'link': 'https://aerzte.ihf-fortbildung.de/formate/fortbildungswochen/',
            'button': 'Details',
            'type': "date"
          },
          {
            'headline': 'Psychosomatische Grundversorgung',
            'text': '50-stündiges Curriculum zur Erbringung psychosomatischer Leistungen.',
            'location': 'Bundesweit',
            'image': 'ihf_academy_start_teaser_slide_7.jpg',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/psychosomatische-grundversorgung',
            'button': 'Details',
            'type': "location"
          },
          {
            'headline': 'Hautkrebs-Screening',
            'text': ' Qualifikation zur Früherkennungs-Untersuchung Hautkrebs.',
            'location': 'Bundesweit',
            'image': 'ihf_academy_start_teaser_slide_8.jpg',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/hautkrebs-screening',
            'button': 'Details',
            'type': "location"
          },
          {
            'headline': 'IHF Mini-Module',
            'text': ' Themen und Vortragssammlung für Qualitätszirkel-Moderatoren. ',
            'location': 'diverse Orte',
            'image': 'ihf_academy_start_teaser_slide_9.jpg',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/mini-module',
            'button': 'Details',
            'type': "location"
          },
          {
            'headline': 'VERAH',
            'text': 'Ihre Hausarztpraxis als zentralen Ort der Versorgung stärken.',
            'location': 'Bundesweit',
            'image': 'ihf_academy_start_teaser_slide_10.jpg',
            'link': 'https://www.verah.de/was-ist-verah/was-sind-verah',
            'button': 'Details',
            'type': "location"
          },
          {
            'headline': 'NäPa-Notfallreferesher',
            'text': 'Ihr praxisorientierter Weg zum Kompetenz- & Abrechnungserhalt für Ihre NäPa.',
            'location': 'Bundesweit',
            'image': 'ihf_academy_start_teaser_slide_11.jpg',
            'link': 'https://www.verah.de/fortbildungen-buchen/verah/naepa-refresher',
            'button': 'Details',
            'type': "location"
          }
        ]
      },
      'webinar_links': {
        'headline': 'Unsere Web-Seminare',
        'text': 'Unter dem Hashtag #WirKommenZuIhnen haben wir für Sie exklusiv eine Vielzahl an digitalen Formaten aufgebaut. Freuen Sie sich auf erstklassige DMP und HZV-relevante Fortbildungen und stets neuen, interessanten Vorträgen. Gewohnt praxisnah, evidenzbasiert und stets produktneutral.',
        'info': 'Regelmäßige Termine',
        'image': 'ihf_academy_start_webinar.jpg',
        'allLink': 'https://www.ihf-fobi.de/fortbildungen-aerzte/web-seminare-ihf',
        'allLinkText': 'Alle anzeigen',
        'links': [
          {
            'headline': 'Update-Multimedikation',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/web-seminare-ihf',
          },
          {
            'headline': 'Dermatoskopie und Dermatologie',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/web-seminare-ihf',
          },
          {
            'headline': 'Antibiotika-Update',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/web-seminare-ihf',
          },
          {
            'headline': 'Palliativmedizin-Update',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/web-seminare-ihf',
          },
          {
            'headline': 'Impfen in der Hausarztpraxis',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/web-seminare-ihf',
          },
          {
            'headline': 'Klima und Gesundheit ',
            'subline': '',
            'link': 'https://www.ihf-fobi.de/fortbildungen-aerzte/web-seminare-ihf',
          }
        ]
      }
    },
    'contact': {
      'header': {
        'headline': 'Kontakt',
        'text': 'Willkommen beim Kundenservice der e-Akademie des IHF. Mit Ihren Rückmeldungen helfen Sie uns, das Angebot für Sie bestmöglich zu gestalten. Sollte einmal etwas unerwartet nicht funktioniert haben, können Sie hier Ihr Serviceanliegen einfach selbst und sofort online an uns adressieren.'
      },
      'info': 'Bitte geben Sie Ihre Anfrage in das Nachrichtenfeld ein. Wenn Sie sich auf ein spezielle Fortbildung beziehen, geben Sie dieses bitte an: Ihre Nachricht kann so direkt an die richtige Stelle geleitet werden.',
      'trainingSelection': 'Fortbildungsauswahl',
      'headline': 'Ihre Anfrage'
    },
    'faq': {
      'header': {
        'headline': 'Hausarzt TV',
        'text': 'Hier finden Sie eine kleine Übersicht an hilfreichen und praxisnahen Erklärvideos für die Hausarztpraxis.'
      },
      'searchButton': 'suchen',
      'searchButtonActive': 'löschen',
      'placeholder': 'Welches Thema suchen Sie?',
      'sidebarAll': 'Alle Themenbereiche'
    },
    'catalog': {
      'header': {
        'headline': 'Katalog',
        'text': 'Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen.  Manchmal benutzt man Worte wie Hamburgefonts, Rafgenduks oder Handgloves, um Schriften zu testen.'
      },
      'bookingDialog': {
        'notice': 'Geben Sie hier Ihre Begründung zur Fortbildungsanfrage ein'
      }
    },
    'courses': {
      'header': {
        'headline': 'Meine Fortbildungen',
        'text': 'Dieser Bereich unterstützt Sie mit der persönlichen Historie Ihrer Teilnahmen und das Führen Ihres Fortbildungsportfolios. Hier finden Sie alle digitalen IHF-Fortbildungen, zu denen Sie bereits angemeldet sind, die Sie aktuell durchführen oder schon zuletzt erfolgreich bestanden haben."'
      }
    },
    'course': {
      'status': {
        'reset': 'Ihr Prüfungsversuch wird bald zurückgesetzt'
      },
      'type': {
        '0': 'Online Fortbildung',
        '1': 'Online Fortbildung',
        '2': 'Online Fortbildung',
        '3': 'Online Fortbildung',
        '4': 'Online Fortbildung',
        '5': 'Online Fortbildung',
        '6': 'Präsenzfortbildung',
        '7': 'Webinar',
        '-2': 'Projekt',
        'undefined': '-'
      },
      'notification': {
        'success1': 'Sie haben die Fortbildung '
      },
      'statusLabel': {
        'training': 'Fortbildung',
      },
      'prefix':{
        'type' : 'Fortbildungstyp'
      },
      'noAssigned': 'Ihnen sind keine Fortbildungen zugewiesen.',
      'noSolved': 'Sie haben noch keine Fortbildungen absolviert. Starten Sie jetzt im Bereich "Meine Fortbildungen" mit Ihrer ersten Lerneinheit!',
      'noAssignedBookable': 'Ihnen sind keine buchbaren Fortbildungen in dieser Kategorie zugewiesen.',
      'noResult': 'Es wurden keine Fortbildungen gefunden die den Kriterien entsprechen.',
      'noResultInCategory': 'Der Kategorie wurden noch keine Fortbildungen zugewiesen.',
      'project': {
        'courses': 'Fortbildungen'
      }
    },
    'courseDetail': {
      'noMeeting': 'Es wurde noch kein Termin für diese Fortbildung angelegt oder alle Termine sind bereits abgelaufen.',
      'title': 'Fortbildungsdetails',
      'classroomNo': 'Fortbildungsnummer',
      'classroomContent': 'Fortbildungsinhalte',
      'location': 'Fortbildungsort',
      'bonusPoints': 'CME-Punkte',
      'backLink': 'Zurück',
      'sidebar': {
        'contentStatus': 'Fortbildungsstatus',
      },
      'reserve': {
        'prebooked': {
          'text': 'Sie haben Ihr Interesse für diese Fortbildung vorgemerkt.',
        }
      }
    },
    'learningProfile': {
      'title': 'Fortbildungsprofil',
      'history': 'Fortbildungshistorie',
      'header': {
        'headline': 'Fortbildungsprofil',
        'text': 'Unser eLearnings ermöglichen Ihnen eine selbstgesteuerte und individuelle Fortbildung mit Ihren mobilen Endgeräten. Als Nutzer entscheiden Sie flexibel zu welchem Zeitpunkt, an welchem Ort und mit welchem Tempo Sie Ihre Fortbildung absolvieren – und hier finden Sie Ihren Stand.'
      }
    },
  },
  'sidebar': {
    'courseType': 'FORTBILDUNGSTYP'
  },
  'button': {
    'login': 'Anmelden',
    'send': 'Senden'
  },
  'notifications': {
    'success': {
      'book': {
        'meeting': 'Sie haben die Präsenzfortbildung erfolgreich gebucht.',
        'course': 'Sie haben die Fortbildung erfolgreich gebucht.'
      },
      'request': {
        'meeting': 'Sie haben die Präsenzfortbildung erfolgreich angefragt.',
        'course': 'Sie haben die Fortbildung erfolgreich angefragt.'
      }
    }
  },
  'trainingmanager': {
    'requestform': {
      'olbodytext': 'Wählen Sie nachfolgend die Namen der weiteren Fortbildungsteilnehmer aus.',
    }
  },
  'custom': {
    'UserDataModal': {
      'headline': 'Bitte vervollständigen Sie ihre Daten.',
      'text': [
        'Unsere eAkademie ist Ihr Zugang zu allen Fortbildungen des IHF. Buchen Sie Web-Seminare, Präsenzfortbildungen oder  Online-Fortbildungen. ',
        'Um das Angebot der IHF nutzen zu können, müssen Sie ihre Daten hinterlegen. Sie finden diese später im Bereich “Mein Profil” und können diese dort jederzeit abrufen und anpasen.'
      ],
      'input': {
        'NA': 'Landesverband',
        'EFN': 'EFN',
        'LANR': 'LANR',
        'ID': 'HÄVG ID'
      },
      'selectNA': [
        {
          'key': '-1',
          'value': 'Bitte wählen'
        },
        {
          'key': 'BW',
          'value': 'Baden-Württemberg (BW)'
        },
        {
          'key': 'BY',
          'value': 'Bayern (BY)'
        },
        {
          'key': 'BEBB',
          'value': 'Berlin und Brandenburg (BEBB)'
        },
        {
          'key': 'BB',
          'value': 'Brandenburg (BB)'
        },
        {
          'key': 'BR',
          'value': 'Braunschweig (BR)'
        },
        {
          'key': 'HB',
          'value': 'Bremen (HB)'
        },
        {
          'key': 'HH',
          'value': 'Hamburg (HH)'
        },
        {
          'key': 'HE',
          'value': 'Hessen (HE)'
        },
        {
          'key': 'MV',
          'value': 'Mecklenburg-Vorpommern (MV)'
        },
        {
          'key': 'NI',
          'value': 'Niedersachsen (NI)'
        },
        {
          'key': 'NO',
          'value': 'Nordrhein (NO)'
        },
        {
          'key': 'RP',
          'value': 'Rheinland-Pfalz (RP)'
        },
        {
          'key': 'SL',
          'value': 'Saarland (SL)'
        },
        {
          'key': 'SN',
          'value': 'Sachsen (SN)'
        },
        {
          'key': 'ST',
          'value': 'Sachsen-Anhalt (ST)'
        },
        {
          'key': 'SH',
          'value': 'Schleswig-Holstein (SH)'
        },
        {
          'key': 'TH',
          'value': 'Thüringen (TH)'
        },
        {
          'key': 'WL',
          'value': 'Westfalen Lippe (WL)'
        }
      ],
      'button': 'Daten speichern'
    }
  }
};
