import { lazyLoading } from '../../utils/helpers';
import Auth from '../../middleware/auth';
import CustomizingLoader from '../../utils/CustomizingLoader';

let routes = [
  {
    path: '/start',
    component: () => CustomizingLoader('views/layout/pages/Layout'),
    showInMenu: true,
    meta: {
      label: 'main_navigation.start',
      middleware: Auth
    },
    children: [{
      path: '',
      name: 'start',
      component: lazyLoading('start'),
      meta: {
        label: 'main_navigation.start',
        middleware: Auth
      }
    }]
  },
  {
    path: '/contact',
    component: () => CustomizingLoader('views/layout/pages/Layout'),
    showInMenu: true,
    meta: {
      label: 'main_navigation.contact',
      middleware: Auth
    },
    children: [{
      path: '',
      name: 'contact',
      component: lazyLoading('contact'),
      meta: {
        label: 'main_navigation.contact',
        middleware: Auth
      }
    }]
  }
];

export default routes;
